import firestore from "../firestore";

export async function getProductsByOwner(user, PC, PIDF, GPC, PADD) {
  if (user && user.uid) {
    console.log("User is authenticated.");
    const userDocRef = firestore.collection(`${PC}`).doc(user.uid);
    const theGPC = firestore.collection(`${GPC}`);
    try {
      const userDoc = await userDocRef.get();

      const products = [];

      if (userDoc.exists) {
        const userDocData = userDoc.data();
        const pIDs = userDocData[PIDF] || [];
        for (const productId of pIDs) {
          const productDocRef = theGPC.doc(productId);

          const productDoc = await productDocRef.get();

          if (productDoc.exists) {
            products.push(productDoc.data());
          }
        }

        const privateAddData = userDocData[PADD] || {};
        for (const productId in privateAddData) {
          products.push(privateAddData[productId]);
        }
      } else {
        console.log("Document does not exist");
      }
      return products;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
export default getProductsByOwner;
