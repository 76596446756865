import firestore from "../firestore";

 export async function addProductToList(user, productId, PC, PIDF) {
    if (user && user.uid) {
        console.log("User is authenticated.");
         const userDocRef = firestore.collection(`${PC}`).doc(user.uid);
         try {
            const userDocSnapshot = await userDocRef.get();
             if (userDocSnapshot.exists) {
                console.log("User document exists.");
                const userDocData = userDocSnapshot.data();
                const PIDF2 = userDocData[PIDF] || [];
                 if (!PIDF2.includes(productId)) {
                    if (productId) {
                        PIDF2.push(productId); 
                        await userDocRef.update({ [PIDF]: PIDF2 }); 
                        console.log(`User document updated with productId ${productId}.`);
                    } else {
                        console.log(`Error: productId is ${productId}.`);
                        return { error: "Error adding product to your list." };
                    }
                     console.log(`Product added to user document.`);
                } else {
                    console.log(`Product already exists in user document.`);
                }
            } else {
                console.log("User document does not exist.");
                await userDocRef.set({ PIDF: [productId] });
                 console.log(`User document created with productId ${productId}.`);
            }
             return { message: "Product added to your list." };
        } catch (error) {
            console.log(error);
            return { error: "Error adding product to your list." };
        }
    } else {
        console.log("User is not authenticated.");
        return { error: "User not authenticated." };
    }
}