import React from "react";
import { createRoot } from "react-dom/client";
import FirebaseProvider from "./contexts/firebase";
import { AuthProvider } from "./contexts/auth";
import App from "./App";

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
    <FirebaseProvider>
        <AuthProvider>
                <App />
        </AuthProvider>
    </FirebaseProvider>


);
