
import { useState, useEffect, useCallback } from 'react';
import LayoutWrapper from "../../components/UI/LayoutWrapper/LayoutWrapper";
import Sidebar from "../../components/Sidebar/Sidebar";
import classes from "./Trends.module.scss";
import Container from "../../components/UI/Container/Container";
import SubHeader from "../../components/Typography/SubHeader/SubHeader";
import axios from "axios";
import productFilters from "./productFilters.json";
import MiniLoader from "../../components/UI/Loader/MiniLoader";

function TrendingProducts() {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [period, setPeriod] = useState(7);
    const [chooseCountry, setCountry] = useState('');
    const [chooseCatID, setCatID] = useState('');
    const [products, setProducts] = useState([]);
    const handlePeriodChange = event => {
        setPeriod(event.target.value);
        viewTable(event.target.value, chooseCountry);
    };
    const handleCountryChange = event => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        if ((selectedOption.text === 'All') || (selectedOption.text === 'Any')) {
            setCountry('');
        } else {
            setCountry(event.target.value);
        }
        viewTable(period, event.target.value);
    };
    const handleCatIDChange = event => {
        setCatID(event.target.value);
        viewTable(event.target.value, chooseCatID);
    };
    function rankDiff(pD) {
        if (!pD || pD.length === 0) {
            //console.error("pD is empty or null");
            return [];
        }
        return pD.map((product) => {
            const { post_change } = product;
            let diffArrow = "--";
            if (post_change !== null) {
                diffArrow = post_change > 0 ? `↑ ${post_change}` : `↓ ${post_change}`;
            }
            //console.log("diffArrow is: " + diffArrow);
            return { ...product, rankDiff: diffArrow };

        });
    }
    function formatNumberShort(value) {
        //console.log('value is: ', value);
        var suffixes = ["", "k", "M", "B", "T"];
        var suffixNum = 0;
        while (value >= 1000 && suffixNum < suffixes.length - 1) {
            value /= 1000;
            suffixNum++;
        }
        // If value is greater than or equal to 1 million, format the value with commas
        if (value >= 1000000) {
            value = (value / 1000000).toLocaleString('en-US', { maximumFractionDigits: 1 }) + 'M';
        } else {
            value = value.toFixed(1) + suffixes[suffixNum];
        }
        //console.log('returning value: ', value);
        return value;
    }
    const viewTable = useCallback((period, chooseCountry, chooseCatID) => {
        setLoading(true);
        const url = process.env.REACT_APP_PRODUCT_TRENDS_API_URL
        //const proxy = 'http://localhost:8010/proxy';
        axios.get(url, '', {
            headers: {
                'Content-Type': 'application/json',
                'Referer-Policy': 'unsafe-url',
                'Access-Control-Allow-Origin': '*'
            },
            crossDomain: true
        }).then(response => {
                setLoading(false);
                //console.log(response.data.body);
                //const pD = response.data.body;
                const pD = response.data;
                if (!pD) {
                    setError("Error: API response was empty.");
                } else {
                    const tableData = rankDiff(pD).map((product) => {
                        const {
                            url_title,
                            comment,
                            cost,
                            cpa,
                            ctr,
                            cvr,
                            post,
                            post_change,
                            first_ecom_category,
                            second_ecom_category,
                            third_ecom_category,
                            impression,
                            like,
                            play_six_rate,
                            share,
                            rankDiff,
                            country: chooseCountry
                        } = product;
                        setProducts(product);
                        const { fvalue } = first_ecom_category.value;
                        const { svalue } = second_ecom_category.value;
                        const { tvalue } = third_ecom_category.value;
                        const first_ecom_category_value = fvalue || "N/A";
                        const second_ecom_category_value = svalue || "N/A";
                        const third_ecom_category_value = tvalue || "N/A";
                        return {
                            url_title,
                            comment: formatNumberShort(comment),
                            cost: formatNumberShort(cost),
                            cpa,
                            ctr,
                            cvr,
                            post: formatNumberShort(post),
                            post_change,
                            first_ecom_category: first_ecom_category_value,
                            second_ecom_category: second_ecom_category_value,
                            third_ecom_category: third_ecom_category_value,
                            impression: formatNumberShort(impression),
                            like: formatNumberShort(like),
                            views: play_six_rate,
                            share: formatNumberShort(share),
                            rankDiff: rankDiff,
                            country: chooseCountry,
                            chartData: product
                        };
                    });
                    setTableData(tableData);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(error.message);
            });
    }, []);
    useEffect(() => {
        viewTable(period, chooseCountry, chooseCatID);
    }, [period, chooseCountry, chooseCatID, viewTable]);
    return (
        <LayoutWrapper>
            <Sidebar />
            <div className={classes.trends__holder}>
                <Container>
                    <SubHeader className={classes.trends__title}>
                        Intel is a must. Our AI provides intel from many difference confidential sources for to use. <br />
                        Don't get left behind. Stay 3 steps ahead, always.
                    </SubHeader>
                    <br />
                    <button style={{ backgroundColor: "black", color: "#ddd", padding: "13px" }}>OPTIONS: </button>
                    <select value={period} onChange={handlePeriodChange}>
                        <option value={7}>7 days</option>
                        <option value={30}>30 days</option>
                    </select>
                    <select name="chooseCatID" id="chooseCatID" onChange={handleCatIDChange}>
                        {productFilters.ecom_category && productFilters.ecom_category.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.value}
                            </option>
                        ))}
                    </select>
                    <select value={chooseCountry} onChange={handleCountryChange}>
                        <option value={'All'}>Any</option>
                        <option value={'US'}>USA</option>
                        <option value={'AR'}>Argentina</option>
                        <option value={'AT'}>Austria</option>
                        <option value={'AU'}>Australia</option>
                        <option value={'BE'}>Belgium</option>
                        <option value={'BG'}>Bulgaria</option>
                        <option value={'BR'}>Brazil</option>
                        <option value={'CA'}>Canada</option>
                        <option value={'CH'}>Switzerland</option>
                        <option value={'CL'}>Chile</option>
                        <option value={'CO'}>Colombia</option>
                        <option value={'CR'}>Costa Rica</option>
                        <option value={'CZ'}>Czech Republic</option>
                        <option value={'DE'}>Germany</option>
                        <option value={'DK'}>Denmark</option>
                        <option value={'DO'}>Dominican Republic</option>
                        <option value={'EC'}>Ecuador</option>
                        <option value={'EE'}>Estonia</option>
                        <option value={'EG'}>Egypt</option>
                        <option value={'ES'}>Spain</option>
                        <option value={'FI'}>Finland</option>
                        <option value={'FR'}>France</option>
                        <option value={'GB'}>United Kingdom</option>
                        <option value={'GR'}>Greece</option>
                        <option value={'GT'}>Guatemala</option>
                        <option value={'HK'}>Hong Kong SAR</option>
                        <option value={'HN'}>Honduras</option>
                        <option value={'HR'}>Croatia</option>
                        <option value={'HU'}>Hungary</option>
                        <option value={'ID'}>Indonesia</option>
                        <option value={'IE'}>Ireland</option>
                        <option value={'IL'}>Israel</option>
                        <option value={'IN'}>India</option>
                        <option value={'IS'}>Iceland</option>
                        <option value={'IT'}>Italy</option>
                        <option value={'JO'}>Jordan</option>
                        <option value={'JP'}>Japan</option>
                        <option value={'KW'}>Kuwait</option>
                        <option value={'KZ'}>Kazakhstan</option>
                        <option value={'LB'}>Lebanon</option>
                        <option value={'LT'}>Lithuania</option>
                        <option value={'LU'}>Luxembourg</option>
                        <option value={'LV'}>Latvia</option>
                        <option value={'MA'}>Morocco</option>
                        <option value={'MK'}>North Macedonia</option>
                        <option value={'MT'}>Malta</option>
                        <option value={'MX'}>Mexico</option>
                        <option value={'MY'}>Malaysia</option>
                        <option value={'NI'}>Nicaragua</option>
                        <option value={'NL'}>Netherlands</option>
                        <option value={'NO'}>Norway</option>
                        <option value={'NZ'}>New Zealand</option>
                        <option value={'OM'}>Oman</option>
                        <option value={'PA'}>Panama</option>
                        <option value={'PE'}>Peru</option>
                        <option value={'PH'}>Philippines</option>
                        <option value={'PL'}>Poland</option>
                        <option value={'PT'}>Portugal</option>
                        <option value={'PY'}>Paraguay</option>
                        <option value={'QA'}>Qatar</option>
                        <option value={'RO'}>Romania</option>
                        <option value={'RS'}>Serbia</option>
                        <option value={'RU'}>Russia</option>
                        <option value={'SA'}>Saudi Arabia</option>
                        <option value={'SE'}>Sweden</option>
                        <option value={'SG'}>Singapore</option >
                        <option value={'TW'}>Taiwan</option>
                        <option value={'TH'}>Thailand</option>
                        <option value={'TR'}>Turkey</option>
                        <option value={'UA'}>Ukraine</option>
                        <option value={'AE'}>United Arab Emirates</option>
                    </select>
                       
                    {loading && <MiniLoader />}
                    {error && <p>{error}</p>}
                    {tableData.length > 0 && !loading && !error && (
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ padding: "20xp", textAlign: "center" }}>Product/Category</th>
                                    <th style={{ paddingRight: "35px" }}>Posts</th>
                                    <th>CPA</th>
                                    <th>CVR</th>
                                    <th>CTR</th>
                                    <th>Cost</th>
                                    <th>Likes</th>
                                    <th>Shares</th>
                                    <th>Views 5sec+</th>
                                    <th>Impressions</th>
                                    <th>Comments</th>
                                    <th>Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.length && tableData.map((rowData, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ fontWeight: "bold", fontSize: "11pt" }}>{rowData.url_title}</div>
                                            <div style={{ fontSize: "8pt" }}>{rowData.first_ecom_category_value}\{rowData.second_ecom_category_value}\{rowData.third_ecom_category_value}</div>
                                        </td>
                                        <td>
                                            <div style={{ fontSize: "12pt" }}>{rowData.post}</div>
                                            <div style={{ fontSize: "10pt", color: rowData.rankDiff.includes("↑") ? "green" : rowData.rankDiff.includes("↓") ? "red" : rowData.rankDiff === "NEW" ? "blue" : "transparent" }}>{rowData.rankDiff}</div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.cpa} USD
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.cvr}%
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.ctr}%
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.cost} USD
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.like}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.share}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.views}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.impression}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ height: "80%", width: "100%", borderRadius: "10px" }}>
                                                {rowData.comment}
                                            </div>
                                        </td>
                                        <td style={{ height: "50px", width: "auto" }}>
                                        </td>

                                        <td>{rowData.country}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Container>
            </div>
        </LayoutWrapper>
    );
};
export default TrendingProducts;



