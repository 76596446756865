import { FirebaseContext } from "./firebase";
import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import Loader from "../components/UI/Loader/Loader";

const initialState = {
    user: null,
    loading: false,
    login: () => { },
    logout: () => { },
    whoIs: () => { },
};

const AuthContext = createContext(initialState);

export function AuthProvider({ children }) {
    const { auth } = useContext(FirebaseContext);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!auth) {
            setLoading(false);
            return;
        }

        const unsubscribe = auth.onAuthStateChanged((user) => {
            setLoading(false);
            setUser(user);
            setError(null);
            //console.log("user: "+user);
        });

        return () => unsubscribe();
    }, [auth, loading]);

    const handleLogin = useCallback(async (email, password) => {
        if (!auth) {
            setError({ message: "Auth object is not available" });
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            await auth.signInWithEmailAndPassword(email, password);
            setUser(auth.currentUser);
            console.log(user);
        } catch (error) {
            setError({ message: error.message });
            console.log(error);
        } finally {
            setLoading(false);
            return { user, error };
        }
    }, [auth, user, error]);
    
    const handleLogout = useCallback(async () => {
        if (!auth) {
            return;
        }

        await auth.signOut();
        setLoading(false);
    }, [auth]);

    function whoIs() {
        setLoading(false);
        return auth.currentUser;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{ user, loading, login: handleLogin, logout: handleLogout, whoIs, error }}
        >
            {children}
        </AuthContext.Provider>
    );
    
}

export default AuthContext;
