import React, {useState, useContext, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/auth';
import usePaymentStatus from '../../hooks/usePaymentStatus';

const NoPay = () => {
    const [loading, setLoading] = useState(true);
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);
    const { user } = useContext(AuthContext);
    const Navigate = useNavigate();
    const theSub = "MOBEcom";
    const theStatus = "completed";
    const { hasSubscriptions, error, psLoading } = usePaymentStatus(
    user ? `${user.email}` : null,
    `${theSub}`,
    `${theStatus}`,
    );
    const ok = useCallback(() => {
        if(hasSubscriptions !== 'no') {
            setSubscriptionStatus(true);
            console.log('subscriptionStatus: '+ subscriptionStatus);
        } 
        
    }, [hasSubscriptions, setSubscriptionStatus, loading, subscriptionStatus, Navigate]);
   
    useEffect(() => {
        setLoading(false);
        // if(!psLoading) {
            console.log('hasSubscriptions: '+ hasSubscriptions);
            if (!loading && !psLoading && hasSubscriptions !== null) {
                setLoading(false);
                //setSubscriptionStatus(true);
                //console.log('subscriptionStatus: '+ subscriptionStatus);
                
                ok();
            } else {
                setSubscriptionStatus(false);
                console.log('subscriptionStatus: '+ subscriptionStatus);
            }

       // }
    }, [loading, psLoading, hasSubscriptions, setSubscriptionStatus, subscriptionStatus, ok]);
    
    return(<div style={{ "margin": "20%" }}> <br /><h4>Haven't Purchaed Access?</h4>Either head back to the login screen, and try again <a href='/logout'>Back to Login</a> OR Please visit <a href="https://mindonbillions.com/product/mobecom/">here</a> to purchase a subscription to activate this service, and make sure to use the same email with your purchase for login here...<br /></div>);


}
export default NoPay;