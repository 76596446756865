import React, { useContext, useEffect } from "react";
import AuthContext from "../../contexts/auth";
import Loader from "../../components/UI/Loader/Loader";
import { Navigate } from "react-router-dom";

function Logout() {
    const { logout, loading } = useContext(AuthContext);

    useEffect(() => {
        logout();
    }, [logout]);

    if (loading) {
        return <Loader />;
    }

    return <Navigate to="/login" replace />;
}

export default Logout;
