import React, { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth";
import LayoutWrapper from "../../components/UI/LayoutWrapper/LayoutWrapper";
import Sidebar from "../../components/Sidebar/Sidebar";
import Container from "../../components/UI/Container/Container";
import classes from "./ProductList.module.scss";
import useProducts from "../../hooks/useProducts";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/Button/Button";
import { addProductToList } from "../../contexts/cartAPI/addProductToList";
import { sanitize } from "../../utils/utils";
import MiniLoader from "../../components/UI/Loader/MiniLoader";
import usePaymentStatus from "../../hooks/usePaymentStatus";
import ImageEditor from "../../components/ImageEditor/ImageEditor";

const productsPerPage = 10;

function ProductList() {
  const [pageNum, setPageNum] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const PC = process.env.REACT_APP_FB_PC
  const PIDF = process.env.REACT_APP_FB_PIDF
  const GPC = process.env.REACT_APP_FB_GPC
  const PADD = process.env.REACT_APP_FB_PADD
  const UC = process.env.REACT_APP_FB_UC
  const { loading, filteredProducts } = useProducts(searchQuery, false, PC, PIDF, GPC, PADD, UC);
  const products = filteredProducts;
  const productsLength = products.length;

  const { hasSubscriptions, psLoading } = usePaymentStatus(
    user ? `${user.email}` : null,
    "ECommob",
    "active"
  );

  localStorage.removeItem("ecom_products");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const handleSearch = useCallback((event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
    setPageNum(1);
  }, []);

  const currentShownProducts = useMemo(() => {
    const startIndex = (pageNum - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    return products.slice(startIndex, endIndex);
  }, [pageNum, products]);

  const handleAddToList = useCallback(async (productId) => {

    try {
      const success = await addProductToList(user, productId, PC, PIDF);
      if (success) {
        alert("Product was added to your Private List!");
      } else {
        alert("An error occurred while adding the product to your Private List!");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while adding the product to your Private List.");
    }
  }, [user]);

  useEffect(() => {
    // Redirect to the login page if the user is not logged in
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  // {hasSubscriptions !== '?' ? (
  //) : (
  //Display message to user to pay subscription
  //<div style={{ "margin": "20%" }}> <br /><h4>Haven't Purchaed Access?</h4>Either head back to the login screen, and try again <a href='/logout'>Back to Login</a> OR Please visit <a href="https://mindonbillions.com/product/mobecom/">here</a> to purchase a subscription to activate this service, and make sure to use the same email with your purchase for login here...<br /></div>
  // )}

  return (
    <>
      <LayoutWrapper>
        <Sidebar />
        <Container>
          <div className={classes.products__holder}>
            <div className={classes.products__title}>
              {loading && <MiniLoader />} {/*Display MiniLoader when loading*/}
              <h2 className={classes.prodlist_page_title}>
                  <span style={{ fontWeight: "bold", fontSize: "32pt", marginBottom: "45px" }}>FLIP</span>
                  <br />
                  <span style={{ color: "#fef3f3" }}>Find, List Items Platform</span>
                </h2>
              <div className={classes.search_bar}>
                <input
                  type="text"
                  placeholder="Search products"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
              <p className={classes.subtitle}>
                Get started by adding any of these products to your private list and paste the data into your storefront.
              </p>
            </div>
            <div className={classes.products_wrapper}>
                {currentShownProducts.length > 0 && (
                  <ul className={classes.products__list}>
                    {currentShownProducts.map((product) => (
                      <li key={product.id} className={product.HOT_SELLER ? classes.hotProduct : ''}>
                        <div className={classes.product}>
                          {product.HOT_SELLER === 1 && (
                            <div className={classes.hotSellerBubble}>HOT</div>
                          )}
                          <ImageEditor product={product.image_link} />
                          <div className={classes.product__description}>
                            <div size="sm" weight="semibold" className={classes.projectTitle}>
                              <p className={classes.product__description__title}>{product.Title} </p><p className={classes.projectPrice}>Listing Price: ${product.price}</p>
                            </div>
                            <p className={classes.product__description__brief}>{sanitize(product.description)}</p>
                            <div className={classes.buttonContainer}>
                              <Link to={`/products/${product.id}`} user={user}>
                                <Button className={classes.button}>View</Button>
                              </Link>
                              {addProductToList ? (
                                <Button className={classes.button} onClick={() => handleAddToList(product.ID)}>Add to List</Button>
                              ) : (
                                <p>Cart context not available.</p>
                              )}
                            </div>
                          </div>
                        </div>

                      </li>
                    ))}
                  </ul>
                )}
              </div>
            <Pagination
              productsLength={productsLength}
              setPageNum={setPageNum}
              productsPerPage={productsPerPage}
              pageNum={pageNum}
            />
          </div>
        </Container>
      </LayoutWrapper>

    </>
  );
}

export default ProductList;