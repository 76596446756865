import { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { FirebaseContext } from "../contexts/firebase";

function useProducts(searchQuery, userId, PC, PIDF, GPC, PADD, UC) {
  const { firestore } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);

  const productsCollection = useMemo(() => {
    if (userId !== false) {
      const theD = firestore.collection(`${UC}`).doc(userId).collection(`${PC}`);
      //console.log("theD:", theD);
      return theD;
    } else {
      const bigD = firestore.collection(`${GPC}`);
      //console.log("bigD:", bigD);
      return bigD;
    }
  }, [firestore, userId, PC, GPC, UC]);

  const getProducts = useCallback(async () => {
    setError(null);
    setLoading(true);

    try {
      let snapshot;

      if (searchQuery) {
        console.log("search query:", searchQuery);
        snapshot = await productsCollection
          .where("title", ">=", searchQuery)
          .where("title", "<=", searchQuery + "\uf8ff")
          .where("description", ">=", searchQuery)
          .where("description", "<=", searchQuery + "\uf8ff")
          .get();
      } else {
        snapshot = await productsCollection.get();
        //console.log("snapshot:", snapshot);
      }

      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("Data: " + data);
      setProducts(data);
    } catch (err) {
      setError("Cannot load products at the moment");
    } finally {
      setLoading(false);
    }
  }, [searchQuery, productsCollection]);

  useEffect(() => {
    getProducts();
  }, [searchQuery, productsCollection, userId, getProducts]);

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      return (
        product?.title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        product?.description?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
    });
  }, [products, searchQuery]);
  return {
    products,
    loading,
    error,
    filteredProducts,
  };
}

export default useProducts;
