import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';
import WebFont from 'webfontloader';
import PropTypes from 'prop-types';
//import { encryptAndEncode } from '../EncryptImage/EncryptImage';
import FontFaceObserver from 'fontfaceobserver';
import { AES, enc } from 'crypto-js';
import * as pako from 'pako';

const encryptionKey = process.env.REACT_APP_IMAGE_CRYPTO_KEY

// Function to convert a binary string to base64
function binaryToBase64(binaryString) {
    return window.btoa(unescape(encodeURIComponent(binaryString)));
}

// Function to convert base64 to binary string
function base64ToBinary(base64) {
    const raw = window.atob(base64);
    let result = "";
    for (let i = 0; i < raw.length; i++) {
        result += String.fromCharCode(raw.charCodeAt(i) & 0xff);
    }
    return result;
}

// Function to encrypt the image
function encryptAndEncode(dataURL, encryptionKey) {
    if (!dataURL || !encryptionKey) {
        console.error('Either imageData or encryptionKey is undefined');
        return null;
    }

    // Encrypt the data
    let encryptedData = AES.encrypt(dataURL, encryptionKey).toString();

    // Convert the encrypted data to a binary string
    let binaryString = base64ToBinary(encryptedData);

    // Convert the binary string to a Uint8Array
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Ensure bytes is a Uint8Array
    if (!(bytes instanceof Uint8Array)) {
        console.error('Expected bytes to be a Uint8Array, but it was ' + typeof bytes);
        return null;
    }

    // Compress the bytes using gzip
    let compressed = pako.gzip(bytes);

    // Convert the compressed data back to base64
    let base64Compressed = binaryToBase64(new Uint8Array(compressed));

    return base64Compressed;
}

// AES encryption function
const ImageEditor = ({ product }) => {
    const [processedImage, setProcessedImage] = useState(null);
    const [processedImageNoE, setProcessedImageNoE] = useState(null);

    useEffect(() => {
        const processImage = async () => {
            try {
                const imageLink = product;

                const imgToCanvas = (img) => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    return canvas;
                };
                //console.log('imageLink:', imageLink);
                const loadImage = () => {
                    return new Promise((resolve, reject) => {
                        fetch(imageLink)
                            .then(res => res.blob())
                            .then(blob => {
                                const image = new Image();
                                image.crossOrigin = 'anonymous';
                                image.src = URL.createObjectURL(blob);
                                image.onload = () => resolve(imgToCanvas(image));
                                image.onerror = reject;
                            })
                            .catch(reject);
                    });
                };


                const loadedImage = await loadImage();
                const imageWidth = loadedImage.width;
                const imageHeight = loadedImage.height;

                const font = new FontFaceObserver('Montserrat');
                await font.load();

                const resized = await new Promise((resolve) => {
                    const img = new fabric.Image(loadedImage);
                    const originalWidth = img.width;
                    const originalHeight = img.height;



                    // Apply filters
                    img.filters.push(
                        new fabric.Image.filters.Blur({ blur: 0.02 }), // Blur filter
                        new fabric.Image.filters.Contrast({ contrast: 0.3 }), // Contrast filter
                        new fabric.Image.filters.Brightness({ brightness: -0.6 }) // Brightness filter (darkens image)

                    );

                    img.applyFilters(); // Apply filters to the image asynchronously

                    // Create the canvas and add the image
                    const canvas = new fabric.StaticCanvas(null, { width: originalWidth, height: originalHeight });
                    canvas.add(img);
                    canvas.renderAll();

                    const watermarkTexts = [
                        { text: 'Ecommob.ai', color: 'red', position: 'top middle', font: 'Arial', fontSize: 0.08 },
                        //{ text: 'GET PRODUCT LISTINGS \nFOR ANY MARKETPLACE\nBACKED BY REAL SALES DATA!', color: 'red', position: 'middle middle', font: 'Arial', fontSize: 0.06 },
                        { text: 'GET ACCESS AT: \nmindonbillions.com/product/mobecom/', color: 'red', position: 'bottom middle', font: 'Arial', fontSize: 0.05 },
                    ];

                    const watermarkPromises = watermarkTexts.map(async (watermarkText) => {
                        await font.load(watermarkText.font);

                        const watermarkObj = new fabric.Textbox(watermarkText.text, {
                            fill: watermarkText.color,
                            fontSize: watermarkText.fontSize * Math.min(canvas.width, canvas.height),
                            fontFamily: watermarkText.font,
                            fontWeight: 'bold',
                            width: canvas.width, // Set the width of the textbox to the width of the canvas
                            splitByGrapheme: true, // Enable splitting of text by grapheme to allow for correct line breaks
                        });


                        const watermarkWidth = watermarkObj.width;
                        const watermarkHeight = watermarkObj.height;

                        const watermarkX = getWatermarkXCoordinate(
                            watermarkText.position,
                            canvas.width,
                            watermarkWidth
                        );

                        const watermarkY = getWatermarkYCoordinate(
                            watermarkText.position,
                            canvas.height,
                            watermarkText.fontSize * Math.min(canvas.width, canvas.height),
                            watermarkHeight
                        );


                        watermarkObj.set({
                            left: watermarkX,
                            top: watermarkY
                        });


                        return watermarkObj;
                    });

                    resolve({ canvas, watermarkPromises });
                });

                const { canvas, watermarkPromises } = resized;
                const objects = await Promise.all(watermarkPromises);

                const group = new fabric.Group(objects);

                canvas.add(group);
                canvas.centerObject(group);

                // Remove metadata from the edited image
                canvas.metadata = {};

                // Convert the processed image to a data URL
                const dataURL = canvas.toDataURL({ format: 'png', quality: 1 });
                setProcessedImageNoE(dataURL);


                let encryptedData = encryptAndEncode(dataURL, encryptionKey);
                //console.log('encryptedData', encryptedData);
                // Create a new image element with the processed image data
                const imageElement = new Image();

                //imageElement.src = 'data:image/png;base64,' + encryptedData;
                console.log('imageElement', typeof imageElement);

                setProcessedImage(canvas.toDataURL('data:image/png;base64,' + encryptedData));

            } catch (error) {
                console.error('Failed to process image:', error);
            }
        };

        processImage();

    }, [product.image_link]);

    const getWatermarkXCoordinate = (position, imageWidth, watermarkWidth) => {
        if (position.includes('middle')) {
            return (imageWidth - watermarkWidth) / 2; // Center the watermark horizontally

            //return imageWidth / 2; // Center the watermark horizontally
        }
        // You can add more conditions here if you have more positions
    };

    const getWatermarkYCoordinate = (position, imageHeight, fontSize) => {
        if (position.includes('top')) {
            return fontSize * 1.5; // Position the watermark at the top with a small margin
        } else if (position.includes('bottom')) {
            return imageHeight - fontSize; // Position the watermark at the bottom with a small margin
        }
        // You can add more conditions here if you have more positions
    };


    return (
        <div>
            {processedImage && <img src={processedImage} alt={product.title} />}
        </div>
    );
};

ImageEditor.propTypes = {
    product: PropTypes.shape({
        image_link: PropTypes.string.isRequired,
    }).isRequired,
};

export default ImageEditor;
