import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.scss"
import Container from "../../components/UI/Container/Container"
import Modal from "../../components/UI/Modal/Modal"
import SubHeader from "../../components/Typography/SubHeader/SubHeader"
import Text from "../../components/Typography/Text/Text"
import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"
import Error from "../../components/Error/Error"
import { useAuth } from "../../hooks/useAuth"

function Login() {
  const { error, loading, isLoggedIn, handleLogin } = useAuth() ?? {}
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [shouldNavigate, setShouldNavigate] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      setShouldNavigate(true)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (shouldNavigate) {
      navigate('/products')
    }
  }, [shouldNavigate, navigate])

  const submitFormHandler = async (e) => {
    e.preventDefault()
    try {
      await handleLogin(email, password)
    } catch (error) {
      setErrorMessage(error.message)
      console.log(error.message)
      alert(error)
    }
  }

  function emailInputHandler(e) {
    setEmail(e.target.value)
  }

  function passwordInputHandler(e) {
    setPassword(e.target.value)
  }

  return (
    <section className={classes.login}>
      <Container className={classes.login__container}>
        <Modal className={classes["login__container__modal"]}>
          <form onSubmit={submitFormHandler} className={classes["login__form"]}>
            
            <SubHeader color="black" className={classes["login__form__title"]}>
            <div className={classes.loaderText}><span style={{fontWeight: "bold", fontSize: "48pt"}}>MOB</span><br /><span style={{fontSize: "44.5pt"}}>F.L.I.P.</span><span style={{fontSize: "34pt", fontWeight: "bold"}}></span></div>
            </SubHeader>
          
            {errorMessage && <Error message={errorMessage} />}
            <Input placeholder="email" onInput={emailInputHandler} />
            <Input placeholder="password" type="password" onInput={passwordInputHandler} />

            <div className={classes["login__form__remember__holder"]}>
              <span color="gray">Remember Me</span><input type="checkbox" label="Remember Me" placeholder="Remember Me" />
            </div>

            <Button className={classes.button} type="submit" disabled={loading}>
              Login
            </Button>
            
            {/*  */}
          </form>
        </Modal>

      </Container>
    </section>
  )
}

export default Login;
