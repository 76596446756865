import React, { memo, useEffect, useState, useContext } from "react";
import classes from "./Sidebar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/auth";

const navLinks = [
  { name: "MOB List", path: "/products" },
  { name: "Private List", path: "/my-products" },
  { name: "Trends", path: "/trendingproducts" },
  { name: "Contact", path: "/contact" },
  { name: "Logout", path: "/logout" },
];

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useContext(AuthContext);

  function handleBodyClick(e) {
    if (document.body.contains(e.target)) {
      setIsOpen(false);
    }
  }

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  function handleLogout() {
    logout();
    navigate("/logout");
  }

  useEffect(() => {
    const handleNavLinkClick = (e) => {
      e.preventDefault();
      navigate(e.target.getAttribute("href"));
      setIsOpen(false);
    };

    const navLinks = document.querySelectorAll(".sidebar__menu a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
    };
  }, [navigate]);

  return (
    <div className={classes.topbar}>
      <div className={classes.logo}>
        <span className={classes.logoTitle}>Mind On Billions</span>
        <span className={classes.logoM}> </span>
        <span className={classes.logoSubtitle}></span>
      </div>
      <div className={classes.hamburger} onClick={handleToggle}>
        <div className={classes.hamburgerLine}></div>
        <div className={classes.hamburgerLine}></div>
        <div className={classes.hamburgerLine}></div>
      </div>
      <ul className={`${classes.menu} ${isOpen ? classes.menuOpen : ""}`}>
        {navLinks.map(({ name, path }, index) => {
          const isActive = location.pathname === path;
          const navLinkClasses = isActive ? classes.active : "";
          return (
            <li key={index} className={classes.menuItem}>
              <a href={path} className={navLinkClasses}>
                {name}
              </a>
            </li>
          );
        })}
        {!isOpen && (
          <li key="logout" className={classes.menuItem}>
            
          </li>
        )}
      </ul>
      {isOpen && (
        <button className={classes.logoutButton} onClick={handleLogout}>
          Logout
        </button>
      )}
    </div>
  );
}

export default memo(Sidebar);
