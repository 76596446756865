import Error404 from "./pages/ErrorPages/Error404";
import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import ProductList from "./pages/ProductList/ProductList";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import CartList from "./pages/CartList/CartList";
import NewProduct from "./pages/Product/NewProduct";
import TrendingProducts from "./pages/Trends/TrendingProducts";
import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import AuthContext from "./contexts/auth";
import ContactForm from "./components/Form/Contact";
import Loader from "./components/UI/Loader/Loader";
import NoPay from "./pages/ErrorPages/NoPay";
import "./App.css";

function App() {
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);
    
    //console.log('user:', user); // Add this line to check the value of user
    //const user = whoIs;

    useEffect(() => {
        const fetchData = async () => {
            if((user !== null) || (user !== undefined)) {
                setLoading(false);
            }
        };
        fetchData();
        
    }, [user]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <Router>
                    
                    <Routes>
                        <Route path="/" element={<ProductList />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/products"
                            element={
                                user ? (
                                    <ProductList />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/my-products"
                            element={
                                user ? (
                                    <CartList />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/trendingproducts"
                            element={
                                user ? (
                                    <TrendingProducts />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/products/:id"
                            element={
                                user ? (
                                    <ProductDetails />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/new-product"
                            element={
                                user ? (
                                    <NewProduct />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/contact"
                            element={
                                user ? (
                                    <ContactForm />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/logout"
                            element={
                                user ? (
                                    <Logout />
                                ) : (
                                    <Navigate to="/login" replace />
                                )
                            }
                        />
                        <Route
                            path="/NoPay"
                            element={
                                
                                    <NoPay />
                            
                            }
                        />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </Router>
            )}
        </>
    );
}

export default App;