import React, { useContext, useState } from "react";
import LayoutWrapper from "../../components/UI/LayoutWrapper/LayoutWrapper";
import Sidebar from "../../components/Sidebar/Sidebar";
import classes from "./ProductDetails.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import Container from "../../components/UI/Container/Container";
import useProduct from "../../hooks/useProduct";
import AuthContext from "../../contexts/auth";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ImageEditor from "../../components/ImageEditor/ImageEditor";

function ProductDetails() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  if (!user) navigate('/logout');
  const { id } = useParams();
  const { product, loading, error } = useProduct(id);
  const handleBackClick = () => {
    navigate(-1);
  };
  const [copyText, setCopyText] = useState("");
  const [copied, setCopied] = useState(false);

  const goToSupplier = (title) => {
    const url = `https://maxmarket.ai/product/${title}`;
    const formattedUrl = url.replace(/\s/g, '-');
    //URI encode the formattedUrl?? How do we do
    window.open(`${formattedUrl}`)
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }
  return (
    <LayoutWrapper>
      <Sidebar />
      <div className={classes.product__holder}>
        <br />
        {loading && <div>Loading...</div>} {/* Display loading message */}
        {error && <div>An error occurred.</div>} {/* Display error message */}
        {product && (
          <>
            <br /><br /><br />
            <div className={classes.detailsTopBar}>
              <button className={classes.detailsButton} onClick={handleBackClick}>Back</button>
            </div>
            <br />
            <br />
            <br />
            <div className={classes.product___details}>
              <div className={classes.product__details__image}>
                <ImageEditor product={product.image_link} />
              </div>
              <div className={classes.product__details__text__holder}>
                <h2 className={classes.product__details__text__title}>{product.Title}</h2>
                <p size="sm" className={classes.product__details__intro}>These are the details you paste into your store front of choice. They have already been tested by us, and pre-calculated for you. <br />
                  <br /> Included in the Pricing Below: <ul><li>A. The cost of goods for the supplier</li><li>B. Around margins for cost of goods and profit are built in by our AI and team! </li></ul> This means that potential profits are already built into the price you see below. If you desire to raise the price it is completely up to you. <br />
                </p>
                <br />
                <h3 className={classes.product__details__text__title}>COPY & PASTE</h3>
                <div size="md" onClick={() => setCopyText(product.Title)}><span style={{ "fontWeight": "bold", "fontSize": "16pt", "color": "#669fd7" }} className={classes.product__details__text__title}>PRODUCT TITLE:</span> {product.Title} <i class="fas fa-copy" onClick={copyToClipboard} style={{ fontSize: "1.2em", marginLeft: "7px" }}> </i> {copied && <i class="fas fa-check"></i>}</div>
                <div size="md" onClick={() => setCopyText(product.price)}><span style={{ "fontWeight": "bold", "fontSize": "16pt", "color": "#669fd7" }} className={classes.product__details__text__title}>LISTING PRICE:</span> {'$' + product.price} <i class="fas fa-copy" onClick={copyToClipboard} style={{ fontSize: "1.2em", marginLeft: "7px" }}> </i> {copied && <i class="fas fa-check"></i>}</div>
                <div size="md" onClick={() => setCopyText(product.sale_price)}><span style={{ "fontWeight": "bold", "fontSize": "16pt", "color": "#669fd7" }} className={classes.product__details__text__title}>SALE PRICE:</span> {'$' + product.sale_price} <i class="fas fa-copy" onClick={copyToClipboard} style={{ fontSize: "1.2em", marginLeft: "7px" }}> </i> {copied && <i class="fas fa-check"></i>}</div>
                <div size="md" className={classes.product__details__description} onClick={() => setCopyText(product.description)}><span style={{ "fontWeight": "bold", "fontSize": "16pt", "color": "#669fd7" }} className={classes.product__details__text__title}>PRODUCT DESCRIPTION: </span>{product.description}</div>
                <i class="fas fa-copy" onClick={copyToClipboard} style={{ fontSize: "1em", }}> copy</i> {copied && <i class="fas fa-check"></i>}
              </div>
            </div>
            <div>
            </div>
          </>
        )}
      </div>
      <br />
    </LayoutWrapper>
  );
}
export default ProductDetails;