import { useEffect, useState } from "react";

const useCheckSubscriptionStatus = (userEmail, sub, status, hasSubscription) => {
    const [psloading, setLoading] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [email, setEmail] = useState(null);
    const [customerID, setCustomerID] = useState(null);
    const url = process.env.REACT_APP_WCCK_API_URL
    const consumerKey = process.env.REACT_APP_WCCK_API_KEY
    const consumerSecret = process.env.REACT_APP_WCCS_API_KEY

    const [hasSubscriptions, setHasSubscriptions] = useState(hasSubscription === '?' ? '?' : sub);

    useEffect(() => {
        const checkPS = async () => {
            try {
                setLoading(true);
                // Retrieve the WooCommerce customer ID using the email from the Firebase user
                const customerResponse = await fetch(`${url}customers?email=${userEmail}&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`, {
                    method: 'GET',
                    Authorization: {
                        username: consumerKey,
                        password: consumerSecret,

                    }
                    //mode: 'no-cors'
                });
                if (customerResponse.message === "Invalid parameter(s): email") {
                    //console.log('No customer');
                    setHasSubscriptions('no');
                    setLoading(false);
                    //return false;
                }
                //console.log('CustomerResponse: ' + JSON.stringify(customerResponse));
                //if(customerResponse) {
                const customers = await customerResponse.json();

                //console.log('Customers 1: ' + customers);
                //console.log('Customers STRING: ' + JSON.stringify(customers));
                //console.log('Customers PARSE: '+JSON.parse(customers));
                const customerId = customers[0].id;
                //console.log('CID: ' + customerId)
                setCustomerID(customerId);
                // Retrieve the customer's subscriptions
                const response = await fetch(`${url}orders?customer=${customerId}&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`, {
                    method: 'GET',
                    Authorization: {
                        'Access-Control-Allow-Origin': '*',
                        //Authorization: `Bearer ${consumerKey}`
                        username: consumerKey,
                        password: consumerSecret,
                    }
                });
                const subscriptions = await response.json();
                //console.log('Subs: ' + JSON.stringify(subscriptions));
                // Check if the user has any active subscriptions
          
                // Initialize a flag to track whether the subscription was found
                // Get the current date and time
                let foundSubscription = false;
                const currentDate = new Date();

                // Iterate over each order in the subscriptions array
                for (const order of subscriptions) {
                    //console.log('Customer Status: '+status);
                    // Check if the order status is "completed"
                    if (order.status === `${status}`) {
                        // Get the date when the order was created
                        const orderCreatedDate = new Date(order.date_created);
                        // Calculate the difference in days between the current date and order created date
                        const diffInDays = (currentDate - orderCreatedDate) / (1000 * 60 * 60 * 24);
                        // Check if the order was created within the last 30 days
                        if (diffInDays <= 30) {
                            // Iterate over each line item in the order
                            for (const line_item of order.line_items) {
                                // Check if the line item name matches the desired subscription name
                                if (line_item.name === sub) {
                                    // Set the flag to true if the subscription is found
                                    foundSubscription = true;
                                    //console.log('Found Sub: ' + foundSubscription);
                                    // You can break out of the loop if the subscription is found
                                    break;
                                }
                            }
                        }
                    }
                    // Break out of the outer loop if the subscription is found
                    if (foundSubscription) break;
                }

                // Set the state based on whether the subscription was found
                if (foundSubscription) {
                    setHasSubscriptions(sub);
                    const hasSubscription = sub;
                    console.log('Sub TRUE: ' + hasSubscriptions + '| ' + hasSubscription);
                } else {
                    setHasSubscriptions('no');
                    const hasSubscription = 'no';
                    console.log('Sub False: ' + hasSubscriptions + '| ' + hasSubscription);

                }
                //console.log('Has Subs 3: ' + hasSubscriptions + '| ' + hasSubscription);
                setLoading(false);
            } catch (error) {
                setErrorMessage(error.message);
                setLoading(false);
                //console.log('Has Subs Error: ' + error)
            }
        }
        checkPS();
        setLoading(false);
    }, []);

    return { psloading, error: errorMessage, hasSubscriptions, hasSubscription, email, customerID, setEmail, setHasSubscriptions };
};
export default useCheckSubscriptionStatus;