import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../contexts/auth";

export function useAuth() {
    const { user, loading, login, logout, whoIs } = useContext(AuthContext);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const isLoggedIn = !!user;
    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, [isMountedRef]);

    async function redirectIfAuth(nextState) {
        if (isLoggedIn) {
            return { user };
        }
    }

    function requireAuth(nextState) {
        if (loading) {
            return { loading: true };
        } else if (!isLoggedIn) {
            return { error: "User is not authenticated" };
        } else if (isLoggedIn && nextState.location.pathname.includes("/products")) {
            return redirectIfAuth(nextState);
        }
    }

    async function handleLogin(email, password) {
        try {
            const currentUser = await login(email, password);
            navigate("/products");
            return currentUser;
        } catch (error) {
            setErrorMessage(error);
            return error;
        }
    }

    async function handleLogout() {
        try {
            await logout();
            navigate("/logout");
        } catch (error) {
            setErrorMessage(error.message);
        }
    }

    return {
        user,
        loading,
        handleLogin,
        handleLogout,
        whoIs,
        requireAuth,
        redirectIfAuth,
        isLoggedIn,
        errorMessage,
    };
}
