import classes from "./Text.module.scss"

function Text({
  children,
  weight = "",
  color = "primary",
  center = false,
  size = "",
}) {
  return (
    <p
      className={`${classes[weight] || ""} ${classes[color]} ${
        classes[center] || ""
      } ${classes[size] || ""}`}
    >
      {children}
    </p>
  )
}

export default Text
