import React from "react";
import LayoutWrapper from "../LayoutWrapper/LayoutWrapper";
import classes from "./Loader.module.scss";
import Container from "../Container/Container";


function Loader() {
    return (
        <LayoutWrapper>
            <Container>

                <div className={classes.customLoadingPage}>
                    <div className={classes.loaderText}><span style={{ fontWeight: "bold", fontSize: "48pt" }}>Mind On Billions</span><span style={{ fontSize: "44.5pt" }}> E-Commerce</span><span style={{ fontSize: "34pt", fontWeight: "bold" }}></span></div>
                    <h6 className={classes.loaderText1}>Waiting...</h6>
                    <div class="loader">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
            </Container>
        </LayoutWrapper>
    );
}

export default Loader;
