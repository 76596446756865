const { Parser } = require("json2csv")

export function sanitize(str) {
  if (typeof str !== "string") {
    return "";
  }
  return str.replace(/[^a-zA-Z ]/g, "");
}


export function convertCSV(array) {
  const json2csvParser = new Parser()
  const csv = json2csvParser.parse(array)

  const csvData = "data:text/csvcharset=utf-8," + encodeURI(csv)
  return csvData
}

export function addDaysInMilli(days) {
  return new Date().getTime() + days * 24 * 60 * 60 * 1000
}

export function calculateRemainingTime(milli) {
  return +milli - new Date().getTime()
}
