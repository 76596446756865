import React, { useContext } from 'react';
import AuthContext from '../../contexts/auth';
import Sidebar from '../../components/Sidebar/Sidebar';
import Container from '../../components/UI/Container/Container';
import LayoutWrapper from '../../components/UI/LayoutWrapper/LayoutWrapper';
import useGetProducts from '../../hooks/useGetProducts';
import { useNavigate } from "react-router-dom";
import classes from '../ProductList/ProductList.module.scss';
const NewProduct = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext);
  if (!user) navigate('/logout');
  const pid = "MB-" + Math.random().toString(36).substr(2, 9);
  //const { createProductByUser } = useCartCtx()
  const initialValues = {
    user: user.uid,
    id: pid,
    title: '',
    description: '',
    availability: '',
    condition: '',
    price: '',
    link: '',
    image_link: '',
    brand: '',
    quantity_to_sell_on_facebook: '',
    vendor_url: '',
    vendor_cogs: '',
    additional_image_link: '',
    google_product_category: '',
    fb_product_category: '',
    sale_price: '',
    sale_price_effective_date: '',
    item_group_id: '',
    color: '',
    gender: '',
    size: '',
    age_group: '',
    material: '',
    pattern: '',
    shipping: '',
    shipping_weight: ''
  }
  const [product, setProduct] = React.useState(initialValues)
  const { lines, createProduct } = useGetProducts();
  const handleChange = (e, field) => {
    setProduct({ ...product, [field]: e.target.value })
  }
  const onSubmit = e => {
    e.preventDefault()
    createProduct(product);
    navigate('/my-products');

  }
  return (
    <LayoutWrapper>
      <Sidebar />
      <Container>
        <div className={classes.newProductTitle}>
          <h2>Add New Product</h2>
        </div>
        <br />
        <p className={classes.newProductSubtitle}>Add a new exclusive item to your private list of products...</p>
        <form className={classes.newProductContainer} onSubmit={onSubmit}>

          <div className={classes.newProductHolder}>
            <div className={classes.newProductRow}>

              <input type="hidden" value={user.uid} disabled notrequired="true" />
              <input type="hidden" value={pid} disabled notrequired="true" />
              <p>Title</p>
              <input value={product.title} onChange={e => handleChange(e, 'title')} notrequired="true" />
              <p>Description</p>
              <input value={product.description} onChange={e => handleChange(e, 'description')} notrequired="true" />
              <p>Availability</p>
              <select value={product.availability} onChange={e => handleChange(e, 'availability')}>
                <option value="">Select Availability</option>
                <option value="In Stock">In Stock</option>
                <option value="Out of Stock">Out of Stock</option>
              </select>
              <p>Condition</p>
              <select value={product.condition} onChange={e => handleChange(e, 'condition')}>
                <option value="">Select Condition</option>
                <option value="New">New</option>
                <option value="Like New">Like New</option>
                <option value="Used">Used</option>
              </select>
              <p>Price</p>
              <input value={product.price} onChange={e => handleChange(e, 'price')} notrequired="true" />
              <p>Link</p>
              <input value={product.link} onChange={e => handleChange(e, 'link')} notrequired="true" />
              <p>Image Link</p>
              <input value={product.image_link} onChange={e => handleChange(e, 'image_link')} notrequired="true" />
              <p>Brand</p>
              <input value={product.brand} onChange={e => handleChange(e, 'brand')} notrequired="true" />
              <p>Quantity to Sell</p>
              <input value={product.quantity_to_sell_on_facebook} onChange={e => handleChange(e, 'quantity_to_sell_on_facebook')} notrequired="true" />
            </div>
            <div className={classes.newProductRow}>
              <p>Vendor URL</p>
              <input value={product.vendor_url} onChange={e => handleChange(e, 'vendor_url')} notrequired="true" />
              <p>Vendor COGS</p>
              <input value={product.vendor_cogs} onChange={e => handleChange(e, 'vendor_cogs')} notrequired="true" />
              <p>Additional Image Link</p>
              <input value={product.additional_image_link} onChange={e => handleChange(e, 'additional_image_link')} notrequired="true" />
              <p>Google Product Category</p>
              <input value={product.google_product_category} onChange={e => handleChange(e, 'google_product_category')} notrequired="true" />
              <p>Facebook Product Category</p>
              <input value={product.fb_product_category} onChange={e => handleChange(e, 'fb_product_category')} notrequired="true" />
              <p>Sale Price</p>
              <input value={product.sale_price} onChange={e => handleChange(e, 'sale_price')} notrequired="true" />
              <p>Sale Price Effective Date</p>
              <input value={product.sale_price_effective_date} onChange={e => handleChange(e, 'sale_price_effective_date')} notrequired="true" />
              <p>Item Group ID</p>
              <input value={product.item_group_id} onChange={e => handleChange(e, 'item_group_id')} notrequired="true" />
            </div>
            <div className={classes.newProductRow}>
              <p>Color</p>
              <input value={product.color} onChange={e => handleChange(e, 'color')} notrequired="true" />
              <p>Gender</p>
              <select value={product.gender} onChange={e => handleChange(e, 'gender')}>
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Any">Any</option>
              </select>
              <p>Age Group</p>
              <select value={product.age_group} onChange={e => handleChange(e, 'age_group')}>
                <option value="">Select Age Group</option>
                <option value="Adult">Adult</option>
                <option value="Children">Children</option>
                <option value="Any">Any</option>
              </select>
              <p>Size</p>
              <input value={product.size} onChange={e => handleChange(e, 'size')} notrequired="true" />
              <p>Material</p>
              <input value={product.material} onChange={e => handleChange(e, 'material')} notrequired="true" />
              <p>Pattern</p>
              <input value={product.pattern} onChange={e => handleChange(e, 'pattern')} notrequired="true" />
              <p>Shipping</p>
              <input value={product.shipping} onChange={e => handleChange(e, 'shipping')} notrequired="true" />
              <p>Shipping Weight</p>
              <input value={product.shipping_weight} onChange={e => handleChange(e, 'shipping_weight')} notrequired="true" />
              {/* ... */}
            </div>

          </div>

        </form>
        <div className={classes.newProductButton} type='submit' onClick={onSubmit}>

          Complete


        </div>
      </Container>
    </LayoutWrapper >
  )
}
export default NewProduct