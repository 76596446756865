import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LayoutWrapper from "../../components/UI/LayoutWrapper/LayoutWrapper";
import Sidebar from "../../components/Sidebar/Sidebar";
import Container from "../../components/UI/Container/Container";
import classes from "../ProductList/ProductList.module.scss";
import CartLine from "../CartList/CartLine/CartLine";
import useGetProducts from "../../hooks/useGetProducts";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/Button/Button";
import AuthContext from "../../contexts/auth";

function CartList() {
  const [loaded, setLoaded] = useState(false);
  const { lines, removeProduct } = useGetProducts();
  const [pageNum, setPageNum] = useState(1);
  const productsPerPage = 10;
  const navigate = useNavigate();
  const endIndex = pageNum * productsPerPage;
  const startIndex = endIndex - productsPerPage;
  const { user } = useContext(AuthContext);
  if (!user) navigate('/logout');
  
  const currentShownLines = useMemo(() => {
    if (!Array.isArray(lines)) return [];
    return lines.slice(startIndex, endIndex);
  }, [lines, startIndex, endIndex]);
  console.log(currentShownLines);

  const navigateToAddProduct = useCallback(() => {
    navigate('/new-product');
  }, [navigate]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [pageNum]);

  return (
    <LayoutWrapper>
      <Sidebar />
      <div className={classes.products__holder}>
        <Container>
          <div className={classes.products__title}>
            <span className={classes.mr5}>{'Private List'}</span>
          </div>
          <div className={classes.products_wrapper}>
            <ul className={classes.products__list}>
              {currentShownLines.length === 0 ? (
                <center><p>You have not added any products to this list.</p></center>
              ) : (
                currentShownLines.map((line, index) => (
                  <li key={index}>
                    <CartLine data={line} removeProduct={removeProduct} setLoaded={setLoaded} PC={process.env.REACT_APP_FB_PC} PIDF={process.env.REACT_APP_FB_PIDF} GCP={process.env.REACT_APP_FB_GPC} PADD={process.env.REACT_APP_FB_PADD} />
                  </li>
                ))

              )}
            </ul>
            {lines.length === 0 ? (
                <p></p>
              ) : (
                <Pagination
                productsLength={lines.length}
                setPageNum={setPageNum}
                productsPerPage={productsPerPage}
                pageNum={pageNum}
              />
              )}
          </div>
        </Container>
      </div>
    </LayoutWrapper>
  );
}

export default CartList;
