import React from "react";
import styles from "./MiniLoader.module.scss";

const MiniLoader = () => {
    return (

        <div className={styles.loaderWrapper}>
            <div class="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    );
}
export default MiniLoader;