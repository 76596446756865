import { useEffect, useState, useContext } from "react";
import firestore from "../contexts/firestore";
import { addProductToList } from "../contexts/cartAPI/addProductToList";
import AuthContext from "../contexts/auth";

function useProduct(id) {
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useContext(AuthContext); 
  const PC = process.env.REACT_APP_FB_PC
  const PIDF = process.env.REACT_APP_FB_PIDF
  const GPC = process.env.REACT_APP_FB_GPC
  const PADD = process.env.REACT_APP_FB_PADD
  useEffect(() => {
    getProduct();
    async function getProduct() {
      setLoading(true);
      const docRef = firestore.collection(`${GPC}`).doc(id);
      const userDocRef = firestore.collection(`${PC}`).doc(user.uid);
      try {
        const doc = await docRef.get();

        if (doc.exists) {
          const data = doc.data();
          setProduct(data);
        } else {
          const userDoc = await userDocRef.get();

          if (userDoc.exists && userDoc.data().hasOwnProperty(`${PADD}`)) {
            const privateAddData = userDoc.data()[PADD];
            const privateProduct = privateAddData.find(item => item.id === id); 

            if (privateProduct) {
              setProduct(privateProduct);
            } else {
              throw new Error(`Product with id ${id} does not exist`);
            }
          } else {
            throw new Error(`Product with id ${id} does not exist`);
          }
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [id, user.uid, GPC, PC, PADD]);


  async function handleAddToCart() {
    try {
      await addProductToList(user, product, PC, PIDF);
      alert("Product added to your list!");
    } catch (err) {
      console.log(err);
      alert("Failed to add product to your list.");
    }
  }

  return { product, loading, error, handleAddToCart };
}

export default useProduct;
