import React from "react";
import LayoutWrapper from "../../components/UI/LayoutWrapper/LayoutWrapper";
import Container from "../../components/UI/Container/Container";
import classes from "./Error404.module.scss";

function Error404() {
    return (
        <LayoutWrapper>
            <Container>
            
                <div className={classes.customLoadingPage}>
                <div className={classes.loaderText}><span style={{fontWeight: "bold", fontSize: "48pt"}}>MOB</span><span style={{fontSize: "44.5pt"}}>E-Com</span><span style={{fontSize: "34pt", fontWeight: "bold"}}>.ai</span></div>
                <h6 className={classes.loaderText1}>404 resource not found.</h6>
                    <div className={classes.loader}>
                    
                       
                    </div>
                    
                </div>
            </Container>
        </LayoutWrapper>
    );
}

export default Error404;
