import React, { createContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "./firebaseConfig";

export const FirebaseContext = createContext();

const FirebaseProvider = ({ children }) => {
    //if (!firebase.apps.length) {
        const app = firebase.initializeApp(firebaseConfig);
    //}

    const auth = app.auth();
    const firestore = app.firestore();

    return (
        <FirebaseContext.Provider value={{ auth, firestore, firebase }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
