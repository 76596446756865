import { Children } from "react"
import classes from "./Pagination.module.scss"

function Pagination({ setPageNum, productsPerPage, productsLength, pageNum }) {
  const totalPages = Math.ceil(productsLength / productsPerPage + 1)
  const pagesArr = Array.from(Array(totalPages).keys()).slice(1)

  function pageIndexCLickHandler(e) {
    setPageNum(e.target.dataset.pageIndex)
  }

  return (
    <ul className={classes.pagination}>
      {Children.toArray(
        pagesArr.map((page, index) => (
          <li
            data-page-index={page}
            key={index}
            onClick={pageIndexCLickHandler}
            className={+page === +pageNum ? classes.active : ""}
          >
            {page}
          </li>
        ))
      )}
    </ul>
  )
}

export default Pagination
