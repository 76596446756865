import React, { useContext, useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../../ProductList/ProductList.module.scss";
import { sanitize } from "../../../utils/utils";
import Button from "../../../components/Button/Button";
import AuthContext from "../../../contexts/auth";
import ImageEditor from "../../../components/ImageEditor/ImageEditor";

const CartLine = React.memo(({ data, removeProduct, PC, PIDF, GPC, PADD }) => {
  const [loaded, setLoaded] = useState(false);

  const {user} = useContext(AuthContext);
  const navigate = useNavigate();
  if(!user) navigate('/logout');
  console.log(PC, PIDF);
  const handleRemove = useCallback(async (e) => {
    e.preventDefault();
    console.log(data);
    
    try {
      // Call the removeProduct function with the product ID
      await removeProduct(data, PC, PIDF, GPC, PADD);
      //alert("Product was removed from your Private List!");
    } catch (error) {
      alert("An error occurred while removing the product from your Private List"+error);
    }
  }, [removeProduct, data, PC, PIDF]);
  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);
  
  return (
      <div className={classes.product}>
      <ImageEditor product={data.image_link} />
        <div className={classes.product__description}>
          <div size="sm" weight="semibold" className={classes.projectTitle}>
              <p className={classes.product__description__title}>{data.Title} </p><p className={classes.projectPrice}>Listing Price: ${data.price}</p>
          </div>
          <br />
          
          <p className={classes.product__description__brief}>
            {sanitize(data.description)}
          </p>
          <div className={classes.buttonContainer}>
          <Link to={`/products/${data.ID}`} className={classes.link}>
              <Button className={classes.button}>View</Button>
          </Link>
          <Button onClick={handleRemove}>
            <span>Remove</span>
          </Button>
          </div>
        </div>
      </div>
  );
});

export default CartLine;
