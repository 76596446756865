import firestore from "../firestore";

export async function removeProductFromList(user, product, PC, PIDF) {
  if (user && user.uid) {
    console.log("User is authenticated.");
    const userDocRef = firestore.collection(`${PC}`).doc(user.uid);
    try {
    await firestore.runTransaction(async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists) {
        throw new Error(`Document for user ${user.uid} does not exist.`);
      }
      const userData = userDoc.data();
      if (!userData[PIDF]) {
        throw new Error(`Field productIDs does not exist for user ${user.uid}.`);
      }
      const pIDs = userData[PIDF];
      const updatedPIDs = pIDs.filter((p) => p!== product.ID);
      transaction.set(userDocRef, { [PIDF]: updatedPIDs }, { merge: true });
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
}
export default removeProductFromList;