import { useEffect, useState, useContext, useCallback } from "react";
import getProductsByOwner from "../contexts/cartAPI/getProductsByOwner";
import removeProductFromList from "../contexts/cartAPI/removeProductFromList";
import AuthContext from "../contexts/auth";

const useGetProducts = () => {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const { user } = useContext(AuthContext);
  const refetchProducts = useCallback(async (PC, PIDF, GPC, PADD) => {
    setLoading(true);
    try {
      const fetchedLines = await getProductsByOwner(user, PC, PIDF, GPC, PADD); // Pass entire user object
      setLines(fetchedLines);
    } catch (error) {
      setError(error);
      console.log(error);
    }
    setLoading(false);
  }, [user]);

  const removeProduct = async (data, PC, PIDF, GPC, PADD) => {
    try {
      // Use the removeProductFromList function
      const success = await removeProductFromList(user, data, PC, PIDF);
      if (success) {
        await refetchProducts(process.env.REACT_APP_FB_PC, process.env.REACT_APP_FB_PIDF, process.env.REACT_APP_FB_GPC, process.env.REACT_APP_FB_PADD);
      } else {
        setError("An error occurred while removing the product from your Private List.");
        console.log("An error occurred while removing the product from your Private List.");
      }
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };
  
  

  // Use the forceUpdate state variable as a dependency in useEffect
  useEffect(() => {
      refetchProducts(process.env.REACT_APP_FB_PC, process.env.REACT_APP_FB_PIDF, process.env.REACT_APP_FB_GPC, process.env.REACT_APP_FB_PADD);
  }, [refetchProducts, forceUpdate]);

  // Include the createProduct function in the return statement
  return { lines, loading, error, removeProduct, refetchProducts };
};

export default useGetProducts;
