import React, { useContext, useState } from 'react';
import AuthContext from '../../contexts/auth';
import firestore from "../../contexts/firestore";
import Sidebar from "../Sidebar/Sidebar";
import Container from "../UI/Container/Container";
import LayoutWrapper from "../UI/LayoutWrapper/LayoutWrapper";
import classes from './Contact.module.scss';
import { v4 as uuidv4 } from 'uuid';

const ContactForm = () => {
    const { user } = useContext(AuthContext);
    const [formState, setFormState] = useState({
        subject: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        UID: user.uid,
        product: 'MOBEcom',
        timestamp: Date.now(),
        id: uuidv4(),
    });

    const handleChange = (event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const docName = `${formState.UID}_${formState.timestamp}`;

        const collectionName = process.env.REACT_APP_FB_CF_CN;
        const collectionRef = firestore.collection(collectionName);
        collectionRef.doc(docName).set(formState);

        setFormState({
            subject: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: '',
            UID: user.uid,
            product: 'Your custom note here',
            timestamp: Date.now(),
            id: uuidv4(),
        });
    };

    return (

        <>
            <LayoutWrapper>
                <Sidebar />
                <Container>
                    <form className={classes.contactForm} onSubmit={handleSubmit}>
                        <label className={classes.label}>
                            Subject
                            <input className={classes.input} type="text" name="subject" value={formState.subject} onChange={handleChange} />
                        </label>
                        <label className={classes.label}>
                            First Name
                            <input className={classes.input} type="text" name="firstName" value={formState.firstName} onChange={handleChange} />
                        </label>
                        <label className={classes.label}>
                            Last Name
                            <input className={classes.input} type="text" name="lastName" value={formState.lastName} onChange={handleChange} />
                        </label>
                        <label className={classes.label}>
                            Email
                            <input className={classes.input} type="email" name="email" value={formState.email} onChange={handleChange} />
                        </label>
                        <label className={classes.label}>
                            Phone Number
                            <input className={classes.input} type="text" name="phoneNumber" value={formState.phoneNumber} onChange={handleChange} />
                        </label>
                        <label className={classes.label}>
                            Message
                            <textarea className={classes.textarea} name="message" value={formState.message} onChange={handleChange} />
                        </label>
                        <input className={classes.submit} type="submit" value="Submit" />
                    </form>
                </Container>
            </LayoutWrapper>
        </>
    );
};

export default ContactForm;
